.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {    
    padding: 6px 6px;    
}
.ant-card-head {    
    min-height: 36px;
    background-color: lightblue;
}
.ant-table-tbody
{
    margin-bottom: 10px;
}